import * as React from "react"
import { storyblokEditable } from "gatsby-source-storyblok"
import Jumbo from "../images/jumbo.svg"
import Diset from "../images/diset.jpeg"
import Dujardin from "../images/dujardin.jpeg"
import Galt from "../images/galt.jpeg"
import Nathan from "../images/nathan.png"
import { Flex, Image } from "theme-ui"
import { keyframes } from "@emotion/react"

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const BrandLogos = {
  jumbo: Jumbo,
  diset: Diset,
  dujardin: Dujardin,
  galt: Galt,
  nathan: Nathan,
}

const Brand = ({ blok }) => {
  return (
    <Flex
      {...storyblokEditable(blok)}
      key={blok._uid}
      sx={{
        width: "100%",
        justifyContent: "center",
        pt: 3,
        opacity: 0,
        animation: `${fadeIn} 1.5s ease-in forwards`,
      }}
    >
      <Image
        src={BrandLogos[blok]}
        alt={blok + " logo"}
        sx={{ maxHeight: "48px" }}
      />
    </Flex>
  )
}

export default Brand
