import * as React from "react"
import { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Flex, Box } from "theme-ui"
import Logo from "../components/logo"
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "gatsby-source-storyblok"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    height: undefined,
  })

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      function handleResize() {
        // Set window height to state
        setWindowSize({
          height: window.innerHeight,
        })
      }

      // Add event listener
      window.addEventListener("resize", handleResize)

      // Call handler right away so state gets updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

const StoryblokEntry = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)

  let windownHeight = useWindowSize()

  if (story.content.body.length === 0 || story.content.body === undefined) {
    return null
  }

  const components = story.content.body.map(blok => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ))

  return (
    <Layout>
      <Helmet title={story.name} defer={false} />
      <Flex
        {...storyblokEditable(story.content)}
        sx={{
          flexDirection: "column",
          minHeight: "100vh",
          minHeight: (windownHeight && windownHeight.height + "px") || "100vh",
          py: 4,
          justifyContent: "space-between",
          //backgroundColor: story.content.background_color || "#ffffff",
          background: "rgb(220,220,220)",
          background:
            "linear-gradient(180deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        {console.log("story.content.body", story.content.body)}
        {story.content.body.length > 0 && <Box>{components}</Box>}
        {story.content.brand && <Logo blok={story.content.brand} />}
      </Flex>
    </Layout>
  )
}

export default StoryblokEntry

export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
